import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// import {
//   ApolloProvider,
//   ApolloClient,
//   HttpLink,
//   InMemoryCache,
// } from "@apollo/client";
// import * as Realm from "realm-web";

import { AuthenticationContextProvider } from "./context/authentication/authentication.context";
import { AnnouncementsContextProvider } from "./context/announcements/announcements.context";
import { TeamsContextProvider } from "./context/teams/teams.context";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationContextProvider>
      <AnnouncementsContextProvider>
        <TeamsContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TeamsContextProvider>
      </AnnouncementsContextProvider>
    </AuthenticationContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
