import React, { useState, createContext, useCallback, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const AnnouncementsContext = createContext();

axios.defaults.withCredentials = true;

export const AnnouncementsContextProvider = ({ children }) => {
  const [announcements, setAnnouncements] = useState([]);
  const [customAnnouncement, setCustomAnnouncement] = useState({
    announcement_type: "custom",
    announcement_title: "",
    announcement_message: "",
    announcement_groups: [],
    files: [],
    deletedFiles: [],
    addedFiles: [],
  });
  const [annSuccess, setAnnSuccess] = useState("");
  const [preshiftAnnouncement, setPreshiftAnnouncement] = useState({
    announcement_type: "preshift",
    files: [],
    announcement_groups: [],
    announcement_sections: [
      { section_name: "Today's Note", value: "" },
      { section_name: "Features/Specials", value: "" },
      { section_name: "New Products", value: "" },
      { section_name: "86’d Products", value: "" },
      { section_name: "Guest Count/Reservations of Note", value: "" },
      { section_name: "Section Assignment", value: "" },
      { section_name: "Incentive Program/Active Promotions", value: "" },
      { section_name: "Upsell Suggestions", value: "" },
    ],
    deletedFiles: [],
    addedFiles: [],
  });
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const setSuccessFunc = successMessage => {
    setAnnSuccess(successMessage);

    setTimeout(() => {
      setAnnSuccess("");
    }, 5000);
  };

  const sendAnnouncement = (operator_name, history, isPreShift = false) => {
    const formData = new FormData();
    setIsLoading(true);

    if (isPreShift) {
      if (preshiftAnnouncement.files) {
        for (let i = 0; i < preshiftAnnouncement.files.length; i++) {
          formData.append("files", preshiftAnnouncement.files[i]);
        }
      }

      formData.append(
        "announcement_type",
        preshiftAnnouncement.announcement_type,
      );
      formData.append(
        "announcement_groups",
        JSON.stringify(preshiftAnnouncement.announcement_groups),
      );
      formData.append("operator_name", operator_name);
      formData.append(
        "announcement_sections",
        JSON.stringify(
          preshiftAnnouncement.announcement_sections.filter(
            el => el.value !== "",
          ),
        ),
      );
    } else {
      if (customAnnouncement.files) {
        for (let i = 0; i < customAnnouncement.files.length; i++) {
          formData.append("files", customAnnouncement.files[i]);
        }
      }

      formData.append(
        "announcement_type",
        customAnnouncement.announcement_type,
      );
      formData.append(
        "announcement_title",
        customAnnouncement.announcement_title,
      );
      formData.append(
        "announcement_message",
        customAnnouncement.announcement_message,
      );
      formData.append(
        "announcement_groups",
        JSON.stringify(customAnnouncement.announcement_groups),
      );
      formData.append("operator_name", operator_name);
    }
    formData.append("operatorId", Cookies.get("userId"));

    axios
      .post(
        process.env.REACT_APP_NODE_ENV === "development"
          ? "https://us-central1-knowbie-dev.cloudfunctions.net/api/api/announcements/create-announcement"
          : "https://knowbie-back.herokuapp.com/api/announcements/create-announcement",
        formData,
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        },
      )
      .then(res => {
        const newAnnouncements = [res.data.announcement, ...announcements];
        setAnnouncements(newAnnouncements);
        history.push("/announcements/all");
        setIsLoading(false);
        setSuccessFunc(
          `You’ve sent a ${isPreShift ? "preshift" : "custom"} announcement`,
        );
      })
      .catch(err => console.log(err));
  };

  const getAnnouncements = useCallback(() => {
    axios
      .get(
        process.env.REACT_APP_NODE_ENV === "development"
          ? "https://us-central1-knowbie-dev.cloudfunctions.net/api/api/announcements/get-announcements"
          : "https://knowbie-back.herokuapp.com/api/announcements/get-announcements",
        {
          headers: { Authorization: `Bearer ${Cookies.get("userId")}` },
        },
      )
      .then(res => {
        setAnnouncements(res.data.announcements.reverse());
      })
      .catch(err => console.log(err));
  }, []);

  const saveAnnouncement = announcementId => {
    axios
      .post(
        process.env.REACT_APP_NODE_ENV === "development"
          ? "https://us-central1-knowbie-dev.cloudfunctions.net/api/api/announcements/save-announcement"
          : "https://knowbie-back.herokuapp.com/api/announcements/save-announcement",
        {
          announcementId,
          operatorId: Cookies.get("userId"),
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        },
      )
      .then(res => {
        if (res.data.announcements.length > 0)
          setAnnouncements(res.data.announcements.reverse());
        setSuccessFunc("Your announcement has been saved");
      })
      .catch(err => console.log(err));
  };

  const deleteAnnouncement = () => {
    axios
      .post(
        process.env.REACT_APP_NODE_ENV === "development"
          ? "https://us-central1-knowbie-dev.cloudfunctions.net/api/api/announcements/delete-announcement"
          : "https://knowbie-back.herokuapp.com/api/announcements/delete-announcement",
        {
          announcementToDelete,
          operatorId: Cookies.get("userId"),
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        },
      )
      .then(res => {
        setCurrentAnnouncement(0);
        setAnnouncements(res.data.announcements.reverse());
        setAnnouncementToDelete(null);
        setSuccessFunc("Your announcement has been deleted");
      })
      .catch(err => console.log(err));
  };

  const editAnnouncement = (history, isPreShift = false) => {
    const formData = new FormData();
    setIsLoading(true);

    if (isPreShift) {
      if (preshiftAnnouncement.addedFiles) {
        for (let i = 0; i < preshiftAnnouncement.addedFiles.length; i++) {
          formData.append("addedFiles", preshiftAnnouncement.addedFiles[i]);
        }
      }

      formData.append("announcement_id", preshiftAnnouncement._id);
      formData.append(
        "announcement_groups",
        JSON.stringify(preshiftAnnouncement.announcement_groups),
      );
      formData.append(
        "deletedFiles",
        JSON.stringify(preshiftAnnouncement.deletedFiles),
      );
      formData.append(
        "announcement_sections",
        JSON.stringify(
          preshiftAnnouncement.announcement_sections.filter(
            el => el.value !== "",
          ),
        ),
      );
    } else {
      if (customAnnouncement.addedFiles) {
        for (let i = 0; i < customAnnouncement.addedFiles.length; i++) {
          formData.append("addedFiles", customAnnouncement.addedFiles[i]);
        }
      }

      formData.append("announcement_id", customAnnouncement._id);
      formData.append(
        "deletedFiles",
        JSON.stringify(customAnnouncement.deletedFiles),
      );
      formData.append(
        "announcement_title",
        customAnnouncement.announcement_title,
      );
      formData.append(
        "announcement_message",
        customAnnouncement.announcement_message,
      );
      formData.append(
        "announcement_groups",
        JSON.stringify(customAnnouncement.announcement_groups),
      );
    }
    formData.append("operatorId", Cookies.get("userId"));

    axios
      .post(
        process.env.REACT_APP_NODE_ENV === "development"
          ? "https://us-central1-knowbie-dev.cloudfunctions.net/api/api/announcements/edit-announcement"
          : "https://knowbie-back.herokuapp.com/api/announcements/edit-announcement",
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then(res => {
        setAnnouncements(res.data.announcements.reverse());
        history.push("/announcements/all");
        setSuccessFunc("You’ve edited an announcement");
        setIsLoading(false);
      })
      .catch(err => console.log(err));
  };

  return (
    <AnnouncementsContext.Provider
      value={{
        setAnnouncements,
        announcements,
        customAnnouncement,
        setCustomAnnouncement,
        sendAnnouncement,
        getAnnouncements,
        saveAnnouncement,
        deleteAnnouncement,
        setAnnouncementToDelete,
        announcementToDelete,
        currentAnnouncement,
        setCurrentAnnouncement,
        preshiftAnnouncement,
        setPreshiftAnnouncement,
        editAnnouncement,
        setAnnSuccess,
        annSuccess,
        isLoading,
      }}>
      {children}
    </AnnouncementsContext.Provider>
  );
};
