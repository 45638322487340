import React, { createContext, useState, useCallback } from "react";
import axios from "axios";

import generator from "generate-password";

import { serverApp } from "../../realm/stores";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [serverSuccess, setSeverSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [importedUsersToCount, setImportedUsersToCount] = useState(0);

  const fetchAllUsers = useCallback(operatorId => {
    axios
      .post("/api/server/get-all-servers", { operatorId })
      .then(res => {
        setUsers([...res.data.allServers]);
      })
      .catch(err => console.log(err));
  }, []);

  const registerNewServers = (servers, operatorId, company) => {
    let successAdded = 0;
    servers.forEach((server, index) => {
      const initialPassword = generator.generate({
        length: 6,
        numbers: false,
        uppercase: true,
        lowercase: false,
      });
      let errorRow = {
        row: index,
        email: server["email_address"],
      };

      serverApp.emailPasswordAuth
        .registerUser(server.email_address.toLowerCase(), initialPassword)
        .then(() => {
          const { email_address, first_name, last_name } = server;
          successAdded++;
          setImportedUsersToCount(successAdded);
          axios
            .post("/api/server/add-new-servers", {
              email_address: email_address.toLowerCase(),
              first_name,
              last_name,
              operatorId,
              initialPassword,
              company,
            })
            .then(() => {
              successAdded++;
              fetchAllUsers(operatorId);
            })
            .catch(err => {});
        })
        .catch(err => {
          console.log("ERROR CSV IMPORT: " + JSON.stringify(errorRow));
        });
    });

    // if (importedUsersToCount > 0) {
    //   setSeverSuccess(true);
    // } else {
    //   setErrorMessage("error");
    // }
  };

  const registerNewServer = (server, operatorId, company) => {
    const { email, first_name, last_name } = server;
    const initialPassword = generator.generate({
      length: 6,
      numbers: false,
      uppercase: true,
      lowercase: false,
    });
    serverApp.emailPasswordAuth
      .registerUser(email.toLowerCase(), initialPassword)
      .then(() => {
        axios
          .post("/api/server/add-new-servers", {
            email: email.toLowerCase(),
            first_name,
            last_name,
            operatorId,
            initialPassword,
            company,
          })
          .then(() => {
            fetchAllUsers(operatorId);
            if (setSeverSuccess) setSeverSuccess(true);
            setErrorMessage(null);
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        setErrorMessage("Someone with this email already exists");
        console.log(err);
      });
  };

  const updateServerInfo = newServerInfo => {
    const {
      _id,
      profileImg,
      first_name,
      last_name,
      email,
      gender,
      address,
      position,
      age,
      invitedDate,
      userRegistered,
      status,
    } = newServerInfo;
    axios
      .post("/api/server/update-server-info", {
        _id,
        profileImg,
        first_name,
        last_name,
        email: email.toLowerCase(),
        gender,
        address,
        position,
        age,
        invitedDate,
        userRegistered,
        status,
      })
      .then(() => fetchUser(_id))
      .catch(err => console.log(err));
  };

  const fetchUser = useCallback(serverId => {
    setErrorMessage(null);
    axios
      .get(`/api/server/get-server/${serverId}`)
      .then(res => {
        setUser(res.data.server);
        setErrorMessage(null);
      })
      .catch(err => setErrorMessage(err));
  }, []);

  const deleteSelectedServers = async (servers, operatorId) => {
    servers.forEach(serverId => {
      axios
        .post("/api/server/delete-server", {
          serverId,
        })
        .then(() => fetchAllUsers(operatorId))
        .catch(err => console.log(err));
    });

    // .then((res) => console.log(res))
    // .then(() => fetchAllUsers(operatorId))
    // .then(() => fetchAllUsers(operatorId))
    // .catch((err) => console.log(err));
  };

  return (
    <UserContext.Provider
      value={{
        users,
        setUsers,
        setUser,
        user,
        isLoading,
        setIsLoading,
        registerNewServers,
        fetchAllUsers,
        fetchUser,
        registerNewServer,
        deleteSelectedServers,
        updateServerInfo,
        setErrorMessage,
        errorMessage,
        serverSuccess,
        setSeverSuccess,
        importedUsersToCount,
        setImportedUsersToCount,
      }}>
      {children}
    </UserContext.Provider>
  );
};
