import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

import useGaTracker from "./utils/useGaTracker";

import { EditModeProvider } from "./context/editModeContext/editMode.context";
import { UserContextProvider } from "./context/users/users.context";

import { AuthenticationContext } from "./context/authentication/authentication.context";

import { app } from "./realm/stores";

import SideMenu from "./components/side-menu/side-menu.component";
import Popup from "./components/popup/popup.component";

const SignUp = lazy(() => import("./pages/sign-up/sign-up.component"));
const Login = lazy(() => import("./pages/login/login.component"));
const RecoveryPassword = lazy(() =>
  import("./pages/recovery-password/recovery-password.component")
);

const AccountPage = lazy(() =>
  import("./pages/account-page/account-page.component")
);
const ManagmentPage = lazy(() =>
  import("./pages/managment/managment-page.component")
);
const ProfilePage = lazy(() =>
  import("./pages/profile/profile-page.component")
);
const DashboardPage = lazy(() =>
  import("./pages/dashboard/dashboard.component")
);
const AnnouncementsPage = lazy(() =>
  import("./pages/announcements/announcements.page")
);
const CreateAnnouncementsPage = lazy(() =>
  import("./pages/create-announcements/create-announcements.page")
);
const Teams = lazy(() => import("./pages/Teams/teams.page"));
const TeamPage = lazy(() => import("./pages/Team/team.page"));
const NotFound = lazy(() => import("./pages/404/404.component"));
// const CoursePage = lazy(() => import("./pages/course/course-page.component"));

const override = css`
  display: block;
  margin: auto;
  border-color: #2e1a47;
`;

const AppWrapper = ({ children }) => {
  return (
    <EditModeProvider>
      <UserContextProvider>
        <Popup />
        {children}
      </UserContextProvider>
    </EditModeProvider>
  );
};

const App = () => {
  const history = useHistory();
  const location = useLocation();

  const { currentUser, isLoading, checkSession } = useContext(
    AuthenticationContext
  );

  useEffect(() => {
    if (!app.currentUser && !location.pathname.includes("/recovery-password"))
      history.push("/login");
  }, [history]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useGaTracker();

  const notLogin = !isLoading && !currentUser;
  const isLogin = !isLoading && currentUser;
  const shouldShowResetPasswordFlow =
    isLogin && currentUser["operator/status"] === "invited";

  if (isLoading) {
    return (
      <AppWrapper>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <ClipLoader
            color="#2E1A47"
            loading={isLoading}
            css={override}
            size={150}
          />
        </div>
      </AppWrapper>
    );
  }

  if (notLogin) {
    return (
      <AppWrapper>
        <Switch>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  color="#2E1A47"
                  // loading={isLoading}
                  css={override}
                  size={150}
                />
              </div>
            }
          >
            <Route
              path="/recovery-password/:recoveryId"
              component={RecoveryPassword}
            />
            <Route path="/login" component={Login} />
            <Route path="/sign-up" component={SignUp} />
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route
              exact
              path="/log-in"
              render={() => <Redirect to="/login" />}
            />
          </Suspense>
        </Switch>
      </AppWrapper>
    );
  }

  if (shouldShowResetPasswordFlow) {
    return (
      <AppWrapper>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader
                color="#2E1A47"
                // loading={isLoading}
                css={override}
                size={150}
              />
            </div>
          }
        >
          <Switch>
            <Route path="/sign-up" component={SignUp} />
            <Route exact path="*" render={() => <Redirect to="/sign-up" />} />
          </Switch>
        </Suspense>
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      <div className="flex flex-row bg-alabaster h-screen overflow-hidden">
        <SideMenu />
        <div
          className={`w-screen flex flex-col items-center overflow-${
            location.pathname.includes("/announcements/all") ||
            location.pathname.includes("/announcements/saved")
              ? "hidden"
              : "auto"
          }`}
        >
          <Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  color="#2E1A47"
                  // loading={isLoading}
                  css={override}
                  size={150}
                />
              </div>
            }
          >
            <Switch>
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route
                exact
                path="/announcement/create"
                component={CreateAnnouncementsPage}
              />
              <Route
                path="/announcements/:type"
                component={AnnouncementsPage}
              />
              <Route
                exact
                path="/announcements/"
                component={AnnouncementsPage}
              />

              <Route exact path="/management/staff" component={ManagmentPage} />
              <Route exact path="/management/teams" component={Teams} />
              <Route
                exact
                path="/management/team/:teamId"
                component={TeamPage}
              />
              <Route path="/account" component={AccountPage} />
              {/* <Route path="/course" component={CoursePage} /> */}
              <Route path="/management/:userId" component={ProfilePage} />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route
                exact
                path="/management"
                render={() => <Redirect to="/management/staff" />}
              />
              <Route
                exact
                path="/login"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route
                exact
                path="/log-in"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route
                path="/sign-up"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>
      </div>
    </AppWrapper>
  );
};

export default App;
