import React from "react";
import { useLocation, Link } from "react-router-dom";

const SideMenuLink = ({
  pathTo,
  text,
  icon,
  handleClick,
  currentLink,
  subLinks,
}) => {
  const location = useLocation();

  const isActiveLink = (path) => {
    return (
      location.pathname.substring(1).split("/")[0] ===
        path.substring(1).split("/")[0] && path
    );
  };

  return (
    <div
      className={`text-plumb tracking-normal overflow-hidden transition-all duration-200 ease-in-out rounded-lg p-4 m-2 w-full flex flex-row justify-start max-h-10 ${
        isActiveLink(pathTo)
          ? "bg-whiteLilac rounded-lg max-h-120"
          : "opacity-40"
      } `}
      style={{
        maxHeight: isActiveLink(pathTo) ? "200px" : "56px",
      }}
    >
      <img
        src={icon}
        alt="side menu icon"
        height="27"
        width="27"
        className="self-start"
      />
      <div>
        <Link
          onClick={handleClick}
          to={pathTo}
          className={`text-base font-bold text-left w-full ml-8 pr-4`}
        >
          {text}
        </Link>
        {subLinks &&
          subLinks.map((subLink, index) => (
            <div key={index} className={`text-left ml-8 mt-5`}>
              <Link
                to={subLink.pathTo}
                className={`font-bold text-base ${
                  location.pathname !== subLink.pathTo
                    ? "opacity-50"
                    : "opacity-100"
                }`}
              >
                {subLink.text}
              </Link>{" "}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SideMenuLink;
