import React, { useState, createContext, useCallback } from "react";
import * as Realm from "realm-web";
import axios from "axios";
import Cookies from "js-cookie";

import { app } from "../../realm/stores";
import { PREFIX } from "../../constants";

export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [errorMes, setErrorMes] = useState(null);
  const [success, setSuccess] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    full_name: "",
    email: "",
    password: "",
    profilePhoto: null,
    menuImg: null,
    menuLink: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const setErrorMessage = err => {
    setErrorMes(err);

    setTimeout(() => {
      setErrorMes(null);
    }, 5000);
  };

  const setSuccessFunc = () => {
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  };

  const setNewPasswordAndUpdateInfo = ({ password, status }) => {
    setIsLoading(true);
    const formData = new FormData();
    const email = userCredentials.email.toLowerCase();
    formData.append("email", userCredentials.email.toLowerCase());
    formData.append("password", password);
    formData.append("full_name", userCredentials.full_name);
    formData.append(
      "profilePhoto",
      !!userCredentials.profilePhoto ? userCredentials.profilePhoto : null,
    );
    formData.append(
      "menuPhoto",
      !!userCredentials.menuImg ? userCredentials.menuImg : null,
    );

    formData.append(
      "menuLink",
      userCredentials.menuLink.length > 0 ? userCredentials.menuLink : null,
    );

    formData.append("status", status);

    app.emailPasswordAuth
      .callResetPasswordFunction(email, password)
      .then(res => {
        axios
          .post("/api/user/update-operator-info", formData)
          .then(res => {
            setErrorMes(null);
          })
          .then(() => {
            logInUser(email, password);
          })

          .catch(err => {
            setIsLoading(false);
            console.log(err);
          });
      })

      .catch(err => {
        // setErrorMes(err);
        setIsLoading(false);
        console.log(err);
      });
  };

  const logInUser = (email, password, setSuccessStatus = false) => {
    const credentials = Realm.Credentials.emailPassword(
      email.toLowerCase(),
      password,
    );

    app
      .logIn(credentials)
      .then(res => {
        axios
          .post(`${PREFIX}/api/user/login`, { email: email.toLowerCase() })
          .then(res => {
            setCurrentUser(res.data.user);
            Cookies.set("userId", res.data.user._id, { expires: 365 });
            if (setSuccessStatus) setSuccess(true);
            setErrorMes(null);
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
            setErrorMes(err.response.data.message);
          });
      })
      .catch(err => {
        setErrorMessage("Incorrect email or password");
      });
  };

  const changePassword = (email, oldPassword, newPassword) => {
    const credentials = Realm.Credentials.emailPassword(
      email.toLowerCase(),
      oldPassword,
    );

    app
      .logIn(credentials)
      .then(() => {
        app.emailPasswordAuth
          .callResetPasswordFunction(email.toLowerCase(), newPassword)
          .then(() => {
            setSuccessFunc();
            return;
          })
          .catch(err => {
            setErrorMessage("Something went wrong");
            console.log(err);
          });
      })
      .catch(err => {
        setErrorMessage("Your old password is incorrect");
        console.log(err);
      });
  };

  const checkSession = useCallback(async () => {
    try {
      if (app.currentUser) {
        const fetchedOperator = await axios.get("/api/user/get-operator");

        if (!fetchedOperator.data.user["operator/active?"]) {
          return app.currentUser.logOut();
        }

        setCurrentUser(fetchedOperator.data.user);

        setIsLoading(false);
      }
    } catch (err) {
      app.currentUser.logOut();
      setCurrentUser(null);
      setIsLoading(false);
    }
  }, []);

  const recoveryPasswordFunc = operEmail => {
    axios
      .post("/api/user/recovery-password", { operEmail })
      .then(res => {
        setSuccessFunc();
      })
      .catch(err => setErrorMessage("There's no user with this email"));
  };

  const changePasswordConfirm = (recoveryToken, newPassword) => {
    axios
      .post("/api/user/change-password", { recoveryToken })
      .then(res => {
        app.emailPasswordAuth
          .callResetPasswordFunction(res.data.operEmail, newPassword)
          .then(() => setSuccess(true))
          .catch(err => console.log(err));
      })
      .catch(err => setErrorMessage("Recovery link expired"));
  };

  const changeMenuLink = updateMenuObj => {
    const formData = new FormData();

    formData.append(
      "menuPhoto",
      !!updateMenuObj.menuImg ? updateMenuObj.menuImg : null,
    );

    formData.append("menuLink", updateMenuObj.menuLink);
    formData.append("operatorId", updateMenuObj.operatorId);

    axios
      .put("/api/user/update-menu-link", formData)
      .then(res => {
        setErrorMes(null);
        setCurrentUser({ ...res.data.user });
      })
      .catch(err => console.log(err));
  };

  return (
    <AuthenticationContext.Provider
      value={{
        setErrorMes,
        userCredentials,
        setUserCredentials,
        errorMes,
        setNewPasswordAndUpdateInfo,
        logInUser,
        changeMenuLink,
        currentUser,
        setCurrentUser,
        success,
        checkSession,
        changePassword,
        setErrorMessage,
        app,
        setSuccess,
        recoveryPasswordFunc,
        changePasswordConfirm,
        isLoading,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
