import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { EditModeContext } from "../../context/editModeContext/editMode.context";
import { AuthenticationContext } from "../../context/authentication/authentication.context";

import SideMenuLink from "../side-menu-link/side-menu-link.component";

import Logo from "../../assets/image_1-removebg-preview 1.svg";
import managementIcon from "../../assets/Group.svg";
import accountIcon from "../../assets/friends.svg";
import chart from "../../assets/chart.svg";
// import courseIcon from "../../assets/open-book.svg";
import logOutIcon from "../../assets/logOut.svg";
import annIcon from "../../assets/ann.svg";

const SideMenu = () => {
  const history = useHistory();

  const [currentLink, setCurrentLink] = useState(window.location.pathname);

  const { editMode, changeEditMode } = useContext(EditModeContext);
  const { app, setCurrentUser } = useContext(AuthenticationContext);

  const handleClick = (pathTo) => {
    setCurrentLink(pathTo);

    if (editMode === true) changeEditMode();
  };

  const signOut = () => {
    app.currentUser
      .logOut()
      .then(() => setCurrentUser(null))
      .then(() => history.push("/"));
  };

  return (
    <div
      className="h-full box-border bg-white  max-w-lg border-r border-grey300"
      // style={{ height: "max-content" }}
    >
      <div className="flex h-full flex-col items-center p-10 pb-12">
        <img src={Logo} alt="logo" height="40" width="137" />
        <div className="flex flex-col text-center mt-10 w-full h-full">
          <SideMenuLink
            text="Dashboard"
            icon={chart}
            pathTo="/dashboard"
            currentLink={currentLink}
            handleClick={() => handleClick("/dashboard")}
          />

          <SideMenuLink
            text="Announcements"
            icon={annIcon}
            pathTo="/announcements"
            currentLink={currentLink}
            handleClick={() => handleClick("/announcements")}
          />

          <SideMenuLink
            text="Management"
            icon={managementIcon}
            pathTo="/management/staff"
            currentLink={currentLink}
            handleClick={() => handleClick("/management")}
            subLinks={[
              {
                text: "Staff",
                pathTo: "/management/staff",
              },
              {
                text: "Teams",
                pathTo: "/management/teams",
              },
            ]}
          />

          <SideMenuLink
            text="Account"
            icon={accountIcon}
            pathTo="/account/info"
            currentLink={currentLink}
            handleClick={() => handleClick("/account/info")}
          />

          <div
            onClick={signOut}
            className="border-t-2 border-creole border-opacity-10 flex flex-row items-end justify-center pt-12  cursor-pointer flex-1 mt-24"
          >
            <img src={logOutIcon} alt="" />
            <p className="ml-7 text-base font-extrabold text-sunsetOrange">
              Sign out
            </p>
          </div>
          {/* <Link to="/course/overview">
            <SideMenuLink
              text="Course"
              icon={courseIcon}
              pathTo="/course/overview"
              currentLink={currentLink}
              handleClick={() => handleClick("/course/overview")}
            />
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
