import React, { createContext, useState } from "react";

export const EditModeContext = createContext();

export const EditModeProvider = ({ children }) => {
  const [editMode, setEditMode] = useState(false);

  const changeEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <EditModeContext.Provider value={{ editMode, changeEditMode }}>
      {children}
    </EditModeContext.Provider>
  );
};
