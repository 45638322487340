import React, { useContext } from "react";

import { AnnouncementsContext } from "../../context/announcements/announcements.context";
import { TeamsContext } from "../../context/teams/teams.context";

import successIcon from "../../assets/successIcon.svg";
import cross from "../../assets/cross.svg";

const Popup = () => {
  const { annSuccess, setAnnSuccess } = useContext(AnnouncementsContext);
  const { teamSuccess, setTeamSuccess } = useContext(TeamsContext);

  return (
    <div
      className={`absolute top-5 right-32 bg-infoSuccess rounded-2xl border border-infoSuccessDark inline-flex items-center h-14 w-1/2 z-50 transform transition-all duration-200 ease-in-out`}
      style={{
        transform: `translateY(${teamSuccess || annSuccess ? "0" : "-100px"})`,
      }}
    >
      <div className="h-full bg-infoSuccessDark rounded-l-xl px-3 flex justify-center items-center">
        <img src={successIcon} alt={successIcon} />
      </div>
      <div className="inline-flex justify-between items-center px-6 w-full">
        <span className="text-plumb font-medium text-base">
          {annSuccess || teamSuccess}
        </span>
        <img
          className="cursor-pointer"
          src={cross}
          alt={cross}
          onClick={() => {
            setTeamSuccess("");
            setAnnSuccess("");
          }}
        />
      </div>
    </div>
  );
};

export default Popup;
